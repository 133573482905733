import React from "react"
import { Text } from "theme-ui"
import Layout from "../../components/layout"
import RoleTemplate from "../../components/roles/RoleTemplate"
import Pagination from "../../components/Pagination"

import portraitImage from "../../images/role-portraits/fighter-cutout.png"
import fighter01Image from "../../images/ability-cards/fighter-04.png"
import fighter02Image from "../../images/ability-cards/fighter-03.png"
import fighter03Image from "../../images/ability-cards/fighter-02.png"
import fighter04Image from "../../images/ability-cards/fighter-01.png"

import YAMLData from "../../data/learning-paths/fighter.yaml"

const FighterPage = () => (
  <Layout>
    <RoleTemplate
      name="fighter"
      src={portraitImage}
      bgGradient="to bottom, #FBB363, #FAA85F"
      introDescription={
        <>
          <Text>The Fighter charges into battle with a fearsome cry, raising their sword to cleave through enemies. They deftly move between foes, countering their attacks and enduring them when necessary. They rally their comrades, forming unshakable bonds with them.</Text>
          <Text>You can use the Fighter role to play all kinds of martial experts. You can be a stoic knight, a glory-seeking gladiator, a wizened veteran, a meditative pugilist, or a raging berserker.</Text>
        </>
      }
      imgWidth={800}
      imgLeftPos="auto"
      imgRightPos={[2, 4, 6]}
      abilityDescription="The Fighter is a sturdy and versatile role that can be used effectively for direct combat and also team support. While they can handily wield weapons of war, they can be equally skilled in matters of the heart. Here are a few of our favorite Fighter abilities that show off this role’s dynamic capabilities."
      abilityImg01={fighter01Image}
      abilityImg02={fighter02Image}
      abilityImg03={fighter03Image}
      abilityImg04={fighter04Image}
      yaml={YAMLData}
    />

    <Pagination parent="roles" prev="wizard" next="invoker" />
  </Layout>
)

export default FighterPage
